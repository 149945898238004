


































import AppState from "@/store/modules/app-module";
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";


import Card from "@/components/material/Card.vue";
import ExtractBookingProperties from "@/components/configuration/extract-booking-data-config.vue"
import config from '@/config';

const appState = getModule(AppState);
@Component({
  components: {
    Card,
    ExtractBookingProperties
  }
})
export default class ConfigurationView extends Vue {
  configurationComponents = [
    { 
      name: "Extract Booking Properties", 
      id: "extractBookingProperties", 
      component: ExtractBookingProperties 
    },  
  ]

  selected: string | null = null;

  tab = 0;

  get selectedComponent() {
    return this.configurationComponents.find(x => x.id ==this.selected)?.component
  }

  setSelected(value: string){
    this.selected = value;
  }

  navigateToCaseManagement(){
    window.location.href = this.CaseManagementLink;
  }

  get color() {
    return appState.apiFault ? "error" : "primary";
  }

  get CaseManagementLink(){
    return `${config.caseManagementUrl}/#/Configuration`
  }

  get ShowCaseManagement(){
    return config.showCaseManagement
  }
}
