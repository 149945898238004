



































































import { Component, Vue, Inject} from "vue-property-decorator";
import Card from "@/components/material/Card.vue";
import ConfigurationService, { BookingProperties, BookingProperty } from "@/services/configuration-service";

import { getModule } from "vuex-module-decorators";
import AppState from "@/store/modules/app-module";

const appState = getModule(AppState);

@Component({
  components: {
    Card
  },
})
export default class StatusChangeMetadataConfig extends Vue {

@Inject() ConfigurationService!: ConfigurationService;

configModel: BookingProperties = { bookingProperties : []}; 

optionToAdd = { name: "", path: "" } as BookingProperty;

editErrors: any[] = []
errors: string[] = [];

AlreadyExistsError = "Already exists!"

resetOption(){
  this.optionToAdd = { name: "", path: "" } as BookingProperty;
}

addToOptions() {
    this.errors = [];
    if(this.isEmptyOrSpaces(this.optionToAdd.path)){
        this.errors.push("path can not be blank")
        return;
    } 

    if(this.isEmptyOrSpaces(this.optionToAdd.name)){
        this.errors.push("name can not be blank")
        return;
    } 

    if(!this.configModel.bookingProperties)
    {
       this.configModel.bookingProperties = [this.optionToAdd];
       this.resetOption();
       return;
    }
    if(this.optionsExists(this.optionToAdd.path, this.optionToAdd.name)) 
    {
        this.errors.push(this.AlreadyExistsError)
        return;
    }

    this.configModel?.bookingProperties.push(this.optionToAdd)
    this.resetOption();
}

editOption(newValue: string, index: number, fieldSelected: string){   
    const currentValue = this.configModel.bookingProperties[index] as any

    if(fieldSelected == "name" && this.optionsExists(currentValue.path, newValue) 
        || fieldSelected == "path" && this.optionsExists(newValue, currentValue.name))
    {
        this.editErrors.push({index, "message": this.AlreadyExistsError})
        return;
    }
    else
    {
        this.editErrors = [];
        currentValue[fieldSelected] = newValue;
    }
}

editError(index: number){
    return this.editErrors.filter(x => x.index == index)
}

optionsExists(path: string, name: string){
    return this.configModel?.bookingProperties.filter(option => 
        option.path.localeCompare(path, undefined, { sensitivity: 'accent'}) === 0  
        &&  option.name.localeCompare(name, undefined, { sensitivity: 'accent'}) === 0    
    ).length > 0
}

get isError() {
    return appState.apiError.message && appState.apiError.message.length > 0
}

isEmptyOrSpaces(str: string){
    return str.trim() === "";
}

removeFromOptions(config: BookingProperty) {
    if(this.configModel !== null){
        this.configModel.bookingProperties = this.configModel?.bookingProperties
            .filter(x=> x.name !== config.name &&  x.path !== config.path)            
        return this.configModel?.bookingProperties;  
    }   
}

async writeToDatabase() {
    this.errors = [];
    try{
        await this.ConfigurationService.saveExtractBookingPropetiesConfig(this.configModel);      
    }catch (error) {
      this.errors.push(error as string)
    }
   
}

async mounted() {
    appState.resetApiError();
    const configModel = await this.ConfigurationService.getExtractBookingPropeties();
    if(this.configModel !== null){
        this.configModel.bookingProperties = configModel.bookingProperties;
    }   
  }
}
